import React, { useContext, useEffect } from "react"
import styled from "styled-components"
import { graphql } from "gatsby"
import { scroller } from "react-scroll"

// Styling
import textStyles, { fontWeights } from "../styles/textStyles"
import colors, { shadows } from "../styles/colors"
import mq from "../styles/breakpoints"

// Context
import AppContext from "../context/AppContext"

// Components
import Seo from "../components/Seo"
import ContentWrapper from "../components/ContentWrapper"
import FAQComponent from "../components/FAQ"
import Button from "../components/atoms/Button"

// Constants
import { paths } from "../constants/paths"

// Types
import { IFAQ } from "../prismic-types/resources"

// Custom Hooks
import { useLocale } from "../hooks/useLocale"

interface IProps {
  data: {
    faq: IFAQ[]
    title: string
    description: string
    contact: {
      buttonLabel: string
      email: string
      title: string
    }
  }
  seo: {
    publishedDate: string
    modifiedDate: string
    lang: string
  }
}

const FAQ: React.FC<IProps> = ({ data, seo: _seo }) => {
  const context = useContext(AppContext)
  const { getLocalizedLink } = useLocale()
  const seo = {
    slug: "faq",
    title: data.title,
    description: data.description,
    ..._seo,
  }

  useEffect(() => {
    if (context && context.scrollToAnchor !== null) {
      scroller.scrollTo(context.scrollToAnchor, {
        duration: 800,
        spy: true,
        smooth: true,
      })
      context.setScrollToAnchor(null)
    }
  }, [])
  return (
    <>
      <Seo
        title={seo.title}
        description={seo.description}
        dateModified={seo.modifiedDate}
        datePublished={seo.publishedDate}
        lang={seo.lang}
        slug={seo.slug}
        breadCrumbItems={[{ name: "FAQ", url: "faq" }]}
        faqItems={data.faq.map((item) => ({
          question: item.title,
          answer: item.text,
        }))}
      />
      <Section>
        <FaqTitle>{data.title}</FaqTitle>
        <StyledContentWrapper>
          <FaqWrapper id="faq">
            <FAQComponent content={data.faq} />
          </FaqWrapper>
        </StyledContentWrapper>
        <ContentWrapper>
          <CTAContainer>
            <CTAText>{data.contact.title}</CTAText>
            <Button
              label={data.contact.buttonLabel}
              href={getLocalizedLink(paths.demo)}
              icon
              fullWidth={false}
            />
          </CTAContainer>
        </ContentWrapper>
      </Section>
    </>
  )
}

const Section = styled.section`
  background: ${colors.greyLight};
  padding: 120px 0;

  ${mq.from.M`
    padding: 168px 0 120px;
  `}
`

const StyledContentWrapper = styled(ContentWrapper)`
  ${mq.from.M`
    max-width: calc(10/12*100%);
  `}

  ${mq.from.XL`
    max-width: calc(8/12*100%);
  `}
`

const FaqTitle = styled.h2`
  color: ${colors.main["200"]};
  ${textStyles.titleXL};
  margin-bottom: 64px;
  padding: 0 24px;

  ${mq.from.M`
    margin-bottom: 120px;
    text-align: center;
    padding: 0;
  `}
`

const FaqWrapper = styled.div`
  flex-direction: column;
  align-items: center;
  display: flex;
  margin-bottom: 64px;

  ${mq.from.M`
    margin: 0 0 120px;
  `}
`

const CTAContainer = styled.div`
  padding: 32px 24px;
  background-color: ${colors.white};
  border-radius: 32px;
  ${shadows.depthFour};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 32px;

  ${mq.from.M`
    padding: 32px;
    flex-direction: row;
    justify-content: center;
  `}

  ${mq.from.L`
    width: calc(10/12*100% - 60px);
    margin: 0 auto;
  `}
`

const CTAText = styled.p`
  ${textStyles.subtitle};
  font-weight: ${fontWeights.bold};
  color: ${colors.main["200"]};
  text-align: center;
`

export const query = graphql`
  query faqPage($locale: String!) {
    prismicFaqPage(lang: { eq: $locale }) {
      last_publication_date
      lang
      first_publication_date
      data {
        #FAQ
        questions {
          question_title {
            text
          }
          question_answer {
            html
          }
        }
        faq_description {
          text
        }
        faq_title {
          text
        }
        contact_button_label
        contact_email
        contact_title {
          text
        }
      }
    }
  }
`

export default ({ data }: any) => {
  const raw = data.prismicFaqPage.data
  const seo = {
    publishedDate: data.prismicFaqPage.first_publication_date,
    modifiedDate: data.prismicFaqPage.last_publication_date,
    lang: data.prismicFaqPage.lang,
  }

  const faq = raw.questions.map((question: any) => {
    return {
      title: question.question_title.text,
      text: question.question_answer.html,
    }
  })

  const faqData = {
    faq,
    title: raw.faq_title?.text || "",
    description: raw.faq_description?.text || "",
    contact: {
      buttonLabel: raw.contact_button_label,
      email: raw.contact_email,
      title: raw.contact_title.text,
    },
  }

  return <FAQ data={faqData} seo={seo} />
}
